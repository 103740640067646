/** @format */

.onboardingwelcome-main-container {
  height: 100vh;
}

.onboardingwelcome-header {
  display: flex;
  align-items: center;
  height: 300px;
  border-radius: 20px;
  background-color: var(--color-primary-pink);
  margin-bottom: 60px;
}

.onboardingwelcome-buttons-fullwidth {
  width: 100% !important;
  justify-content: space-between !important;
}

.onboardingwelcome-buttons-top-spacing {
  margin-top: 20px;
}

.onboardingwelcome-header-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
