/**
 * brokers profile
 *
 * @format
 */

.broker-profile-loading-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.profile-description-container {
  margin-bottom: 130px;
  margin-top: 20px;
  flex: 4;
}

.profile-description-container div {
  margin-bottom: 5px;
}

.profile-usplist {
  padding-left: 1.2rem;
}

.profile-usplist li {
  text-transform: capitalize;
}

.profile-usp-container {
  border-left: 1px solid var(--color-neutral-dark-500);
  flex: 1;
}

.profile-my-services-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}

.profile-my-services-row p {
  border: 1px solid var(--color-primary-yellow);
  border-radius: 50px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-usp-inner-container {
  padding-left: 40px;
}

.profile-container {
  display: flex;
  flex-direction: row;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.profile-container-left {
  flex: 2;
  align-self: flex-start;
}

.profile-container-left img {
  width: 220px;
  height: auto;
}

.profile-container-right {
  flex: 7;
  display: flex;
  flex-direction: column;
}

.profile-heading-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile-verified-container {
  border-radius: 50px;
  border: 1px solid var(--color-secondary-green);
  padding: 10px 20px;
  color: var(--color-secondary-green);
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
}

.profile-verified-container img {
  padding-right: 8px;
}

.profile-container .profile-heading-container .profile-heading-icon {
  margin: 15px 30px;
}

.profile-container-right .profile-address-container {
  display: flex;
  width: 78%;
}

.profile-container-right .profile-address-container .profile-address-icon {
  flex: 1;
}

.profile-container-right .profile-address-container .profile-address-address {
  flex: 15;
  align-self: center;
}

.contact-icons-container {
  display: flex;
  width: 200px;
  margin-top: 32px;
}

.contact-icons-container a {
  margin-right: 5px;
}

.broker-status-container {
  display: flex;
  border: 1px solid var(--color-neutral-dark-600);
  box-sizing: border-box;
  width: 600px;
  height: 104px;
  border-radius: 16px;
  margin-top: 40px;
  align-items: center;
  padding: 5px;
}

.broker-status-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.broker-status-middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--color-neutral-dark-500);
  border-right: 1px solid var(--color-neutral-dark-500);
  padding: 0 20px;
}

.broker-status-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.broker-status-item-top {
  flex: 1;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--color-neutral-dark-600);
}

.broker-status-item-bottom {
  flex: 1;
  text-align: right;
  font-size: 24px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0em;
  margin-top: 15px;
}

.broker-status-item-bottom.highlighted-text {
  text-transform: uppercase;
  color: var(--color-secondary-green);
}

/** brokers object listing */

.broker-profile-view .content-wrapper {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.broker-profile-view .content-wrapper .nav-link {
  max-width: 1200px;
  color: var(--color-neutral-dark-600);
  font-weight: 400;
}

.broker-profile-view .content-wrapper .nav-link.active {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-900);
  font-weight: 500;
  border-bottom: 3px solid var(--color-neutral-dark-900) !important;
}

.broker-profile-view .content-wrapper .nav-link:hover {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-700);
  border-bottom: 3px solid var(--color-neutral-dark-700) !important;
}

.broker-profile-view .content-wrapper .listing-container {
  margin: auto;
  justify-content: flex-start;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.listing-container-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.broker-profile-view .listing-box {
  margin: 15px 1px;
  width: 344px;
  border: none;
}

.broker-profile-view .equal-height-JlocK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.broker-profile-view .listing-box-img-container {
  position: relative;
  width: 100%;
}

.broker-profile-view .listing-box-img {
  max-height: 215px;
}

.broker-profile-view .listing-box-sold-tag {
  position: absolute;
  bottom: 0;
  left: 20px;
  background-color: var(--color-primary-yellow);
  padding: 5px 15px;
  border-radius: 50px;
  border: 1px solid white;
}

.broker-profile-view .listing-location {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.broker-profile-view .listing-price {
  line-height: 45px;
}

.broker-profile-view .icon-strip-container {
  display: flex;
  flex-direction: row;
  width: 250px;
  margin: 18px 0;
}

.icon-strip-box {
  display: flex;
  flex: 1;
  height: 25px;
  flex-direction: row;
}

.icon-strip-icon {
  flex: 1;
  height: 25px;
  width: 25px;
}

.icon-strip-text {
  flex: 1;
  height: 25px;
  width: auto;
  padding-left: 5px !important;
  white-space: nowrap;
}

.broker-profile-view .filter-objects-form {
  max-width: 1160px;
  margin-top: 40px;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.broker-profile-view .filter-objects-form label {
  font-weight: 500;
  line-height: 40px;
}

.broker-profile-view .filter-objects-form .add-top-margin {
  margin-top: 40px;
}

.broker-profile-view .filter-objects-form .price-seperator {
  margin-top: 50px;
  width: 20px !important;
}

.broker-profile-view .filter-objects-form .add-top-margin.button-filter {
  margin-top: 37px;
  min-width: unset;
}

.broker-profile-view .filter-objects-form .input-location {
  width: 10vw;
}

.broker-profile-view .filter-objects-form .input-property-type {
  width: 20vw;
}

.broker-profile-view .filter-objects-form .input-min-price {
  width: 10vw;
}

.broker-profile-view .filter-objects-form .input-max-price {
  width: 10vw;
}

#copy-success-overlay
  .bs-tooltip-auto[data-popper-placement^='right']
  .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--color-primary-pink) !important;
}

#copy-success-overlay.tooltip .tooltip-inner {
  background-color: var(--color-primary-pink);
  font-size: 14px;
}

.profile-heading-icon.icon-copy-pink:hover {
  opacity: 75%;
  cursor: pointer;
}

.broker-profile-show-more-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.broker-profile-subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
}

.broker-profile-not-public-container .broker-profile-not-public-actions {
  position: relative;
  top: 250px;
}

.broker-profile-not-public-container .image-broker-profile-not-public {
  margin: -250px auto;
}

.broker-profile-not-public-container h2 {
  color: var(--color-neutral-neutral-100);
  font-weight: 900;
  font-size: 64px;
  line-height: 80px;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  z-index: 100;
}
.broker-profile-not-public-container .btn {
  margin: auto;
}

.broker-profile-view .card .card-img-top {
  border-radius: 24px;
}

.broker-profile-view .description-box {
  width: 94%;
  max-height: 144px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  margin-bottom: 20px;
}

.profile-description-and-usp-box {
  display: flex;
}

.broker-properties-banner {
  height: 130px;
  width: 100%;
  background: var(--color-primary-pink-light);
  border: 2px solid var(--color-primary-pink);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.broker-properties-banner .banner-banner-left {
  flex: 1;
}

.broker-properties-banner .banner-banner-right {
  flex: 5;
}

@media (min-width: 920px) {
  .broker-profile-view {
    display: contents;
  }

  .broker-profile-view .contact-icon-container-mobile {
    display: none;
  }

  .contact-icon-container-desktop {
    display: block;
  }

  .broker-profile-subheading {
    display: block;
  }

  .broker-profile-view-mobile {
    display: none;
  }
  .image-profile-foreground-mobile {
    display: none;
  }

  .profile-container-right
    .profile-heading-container
    .profile-address-container {
    display: none;
  }

  .profile-container-right .profile-address-container {
    display: flex;
  }
  .profile-container .profile-heading-container {
    display: flex;
  }

  .mobile-tabs {
    display: none;
  }
  .profile-container-right .profile-description-container,
  .profile-container-right .profile-my-services-container {
    display: block;
  }

  .broker-profile-view .profile-description-and-usp-box .profile-usp-container {
    display: block;
    height: max-content;
  }

  .broker-profile-view-mobile .profile-usp-container-mobile {
    display: none;
  }
}

@media (max-width: 919px) {
  .mobile-hidden {
    display: none;
  }

  .image-profile-background,
  .image-profile-overlay,
  .image-profile-foreground,
  .contact-icon-container-desktop {
    display: none;
  }

  .broker-profile-subheading {
    display: none;
  }

  .mobile-tabs {
    display: block;
    margin-top: 25px;
  }

  .profile-container-right .profile-description-container,
  .profile-container-right .profile-my-services-container {
    display: none;
  }

  .profile-description-container,
  .profile-my-services-container,
  .profile-usp-container-mobile {
    padding: 16px;
  }

  .list-of-objects-container
  /* .broker-profile-show-more-container  */ {
    display: none;
  }

  .mobile-tabs .list-of-objects-container
  /* .mobile-tabs .broker-profile-show-more-container */ {
    display: block;
  }

  .broker-profile-view-mobile .profile-verified-container {
    width: max-content;
    margin-top: 10px;
  }

  .image-profile-foreground-mobile {
    display: block;
    border-radius: 16px;
    max-height: 250px;
    width: initial;
    margin: auto;
  }

  .profile-heading-container {
    justify-content: left;
    flex-direction: column;
  }

  .profile-container-right
    .profile-heading-container
    .profile-address-container {
    display: flex;
  }

  .profile-container-right .profile-address-container {
    display: none;
  }

  .broker-profile-view {
    display: none;
  }

  .profile-usp-container {
    border-left: none;
  }

  .profile-usp-inner-container {
    padding-left: 0px;
  }

  .broker-profile-view-mobile {
    display: contents;
  }

  .broker-profile-view-mobile .profile-container {
    flex-direction: column;
    margin: 0 20px;
  }

  .broker-profile-view-mobile .profile-container-left {
    align-self: center;
  }

  .broker-profile-view-mobile
    .profile-heading-container
    .profile-heading-heading {
    font-size: 20px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }

  .broker-profile-view-mobile
    .profile-container-right
    .profile-address-container {
    margin-top: -22px;
  }

  .broker-profile-view-mobile
    .profile-container-right
    .profile-address-container
    .profile-address-address {
    font-size: 12px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 7px;
    margin-top: -2px;
  }

  .broker-profile-view-mobile .contact-icons-container {
    width: 160px;
    margin-top: 0;
  }

  .broker-profile-view-mobile .contact-icons-container .icon-phone-pink,
  .broker-profile-view-mobile .contact-icons-container .icon-email-pink,
  .broker-profile-view-mobile .contact-icons-container .icon-copy-pink {
    width: 67px;
    height: 42px;
    background-position: left;
  }

  /* .profile-heading-icon {
    display: none;
  } */

  .broker-profile-view-mobile .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .profile-container {
    flex: 1;
  }

  .broker-profile-tabs-mobile {
    flex: 1;
    margin-top: 20px;
  }

  .broker-profile-view-mobile .tab-content {
    flex: 1;
  }

  .broker-profile-view-mobile .tab-content .tab-pane {
    margin: 30px auto;
  }

  .broker-profile-view-mobile .tab-content .tab-pane .card {
    flex-direction: row;
    border: unset;
  }

  .broker-profile-view-mobile .tab-content .tab-pane .card .card-img-top {
    height: 142px;
    width: 112px;
    border-radius: 24px;
  }

  .broker-profile-view-mobile .tab-content .tab-pane .card-body {
    padding: 0 1rem;
  }

  .broker-profile-view-mobile .broker-status-container {
    flex-direction: column;
    width: 87%;
    height: auto;
    margin: 0 auto 100px;
  }

  .broker-profile-view-mobile
    .tab-content
    .broker-status-container
    .broker-status-middle {
    flex-direction: column;
    width: auto;
    height: auto;
    border-left: unset;
    border-right: unset;
    border-top: 1px solid var(--color-neutral-dark-500);
    border-bottom: 1px solid var(--color-neutral-dark-500);
  }

  .broker-profile-view-mobile .broker-status-item-top {
    text-align: center;
    line-height: 24px;
  }

  .broker-profile-view-mobile .broker-status-item-bottom {
    text-align: center;
    margin-top: 15px;
    font-size: 40px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
  }

  .broker-profile-view-mobile .broker-status-item-bottom.highlighted-text {
    text-transform: none;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
  }

  .broker-profile-view-mobile .broker-status-left {
    padding: 40px 20px;
  }
  .broker-profile-view-mobile .broker-status-middle {
    padding: 40px 20px;
  }
  .broker-profile-view-mobile .broker-status-right {
    padding: 40px 20px;
  }

  .broker-profile-view-mobile .content-wrapper .nav-link {
    max-width: 1200px;
    color: var(--color-neutral-dark-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }

  .broker-profile-view-mobile .content-wrapper .nav-link.active {
    border-left-color: var(--color-neutral-neutral-100);
    border-right-color: var(--color-neutral-neutral-100);
    border-top-color: var(--color-neutral-neutral-100);
    color: var(--color-neutral-dark-900);
    font-weight: 500;
    border-bottom: 3px solid var(--color-neutral-dark-900) !important;
  }

  .broker-profile-view-mobile .content-wrapper .nav-link:hover {
    border-left-color: var(--color-neutral-neutral-100);
    border-right-color: var(--color-neutral-neutral-100);
    border-top-color: var(--color-neutral-neutral-100);
    color: var(--color-neutral-dark-700);
    border-bottom: 3px solid var(--color-neutral-dark-700) !important;
  }

  .broker-profile-tabs-mobile .icon-strip-icon {
    height: 20px !important;
    width: 20px !important;
  }

  .broker-profile-view-mobile .icon-strip-container {
    width: 185px;
    display: flex;
    flex-direction: row;
  }

  .broker-profile-view-mobile .icon-strip-container .icon-strip-text {
    font-weight: 400;
  }

  .broker-profile-view-mobile .listing-container.row {
    padding-left: 18px;
    padding-right: 18px;
  }

  .broker-profile-view-mobile .broker-profile-not-public-container {
    width: 100vw;
    overflow-x: clip;
    margin-left: -13px;
  }

  .broker-profile-view-mobile .image-broker-profile-not-public {
    width: 567px;
    height: 764px;
  }

  .broker-profile-view-mobile
    .broker-profile-not-public-container
    .broker-profile-not-public-actions {
    top: 120px;
  }

  .broker-profile-view-mobile .broker-profile-not-public-actions h2 {
    font-size: 40px;
    line-height: initial;
  }

  .broker-profile-view-mobile .broker-profile-not-public-actions .btn {
    position: relative;
    z-index: 1;
    width: 90%;
  }

  .broker-profile-view-mobile .profile-usp-container-mobile {
    display: block;
  }

  .broker-profile-view .profile-usp-container,
  .broker-profile-view-mobile .profile-usp-container {
    display: none;
  }

  .broker-profile-view-mobile .profile-description-container {
    margin-bottom: 0;
  }
  .broker-profile-view-mobile .broker-properties-banner {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 15px;
    height: auto;
  }

  .broker-profile-view-mobile .broker-properties-banner .banner-banner-right {
    font-size: 16px;
    font-weight: 500;
  }

  .broker-profile-view-mobile
    .broker-properties-banner
    .banner-banner-left.icon-exclamation {
    align-self: flex-start;
    width: 30px;
    height: 30px;
    flex: 0.7 0;
  }
}
