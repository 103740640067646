/** @format */

.onboarding-myservices-grid {
  display: grid;
  grid-template-columns: max-content auto;
  row-gap: 10px;
  column-gap: 50px;
  margin-bottom: 30px;
}

.onboarding-myservices-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onboarding-myservices-switch-text {
  margin-left: 20px;
}

.onboarding-myservices-pink-text {
  color: var(--color-primary-pink) !important;
}

.onboardingsteps-content-body{
  justify-content: start;
}
