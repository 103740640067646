/** @format */

.onboardingsignup-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
}

.onboardingsignup-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.onboardingsignup-field-input-spacing {
  margin-bottom: 40px;
}

.onboardingsignup-button-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.onboardingsignup-button-spacing {
  margin-left: 20px;
}

.ssnError {
  border: 2px solid var(--color-primary-red);
}

input[type="number"].telephone::-webkit-outer-spin-button,
input[type="number"].telephone::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"].telephone {
    -moz-appearance: textfield;
}

input[type="number"].telephone {
  -moz-appearance: textfield;
}
input[type="number"].telephone:hover,
input[type="number"].telephone:focus {
  -moz-appearance: number-input;
}