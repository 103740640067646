/**
 * fee calculator
 *
 * @format
 */

.fee-calculator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 100px auto auto 1.75rem;
}

.heading-container {
  flex: 1;
}

.description-container {
  flex: 1;
  max-width: 761px;
}

.fee-calculator-container .input-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.fee-calculator-container .input-container .form-control {
  text-align: center;
  width: 297px;
  border-bottom: 1px dashed var(--color-neutral-dark-700);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.fee-calculator-container .input-container .form-control:focus,
.fee-calculator-container .input-container .form-control:hover,
.fee-calculator-container .input-container .form-control:visited,
.fee-calculator-container .input-container .form-control:active {
  border-bottom: 1px dashed var(--color-neutral-dark-900) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.inner-container .input-label {
  flex: 1;
  line-height: 24px;
  margin-top: 8px;
}

.inner-container .input-box {
  flex: 1;
}

.result-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 492px;
  width: -webkit-fill-available;
  height: 182px;
  border: 2px solid var(--color-secondary-yellow-light);
  border-radius: 16px;
  margin: 80px auto;
  margin: 40px;
}

.result-container p {
  flex: 1;
}

.result-container .resultText {
  color: var(--color-secondary-yellow-light);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
}

.result-container .view-calculation {
  position: relative;
  display: block;
}

.fee-calculator-container .view-calculation a {
  text-decoration: underline !important;
  color: var(--color-neutral-dark-900) !important;
}

.fee-calculator-container .view-calculation a:hover {
  color: var(--color-neutral-dark-600) !important;
}

/** view calculation modal **/

.fee-calculator-modal .modal-content {
  padding: 15px;
}

.fee-calculator-modal ul {
  list-style-type: none;
}

.fee-calculator-modal ul li {
  padding-bottom: 15px;
}

.fee-calculator-modal .message-modal-header.h4 {
  color: var(--color-primary-pink-dark);
  text-align: left;
}

.fee-calculator-modal .modal-body {
  margin: unset;
  min-width: 350px;
}

.fee-calculator-modal .modal-footer {
  justify-content: right !important;
  padding: 0 50px;
}

.fee-calculator-modal .fee-calculator-modal-footer {
  text-align: right;
  color: var(--color-secondary-yellow-light);
}

@media (max-width: 919px) {
  .fee-calculator-container {
    margin: 20px auto 140px 0;
  }

  .result-container {
    height: 175px;
    margin: 80px 25px;
    margin-top: 40px;
  }

  .result-container .resultText {
    max-width: 200px;
    margin: auto;
  }

  .fee-calculator-container a {
    margin-top: -35px;
  }

  .fee-calculator-container .description-container {
    padding: 20px;
  }
}
