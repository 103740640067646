/** @format */

.modal .modal-content{
  margin: auto;
  width: 100% !important;
}

.modal-inner-container {
  margin-bottom: 100px;
}

.modal-inner-container h3{
  text-align: center;
}

.modal-field-container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-dark-700);
}

.modal-field-container.field-group .modal-field-input{
margin-bottom: 0;
}

.modal-description {
  margin-bottom: 40px;
  text-align: center;
}

.modal-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  text-align: left;
  border: 1px solid var(--color-neutral-dark-600);
}
.modal-spinner {
  margin:20px;
}

.modal-field-textarea {
  min-height: 150px;
  border-radius: 24px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
  resize: none;
  text-align: left;
}

.modal-field-textarea:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.modal-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}


.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.modal-button-spacing {
  margin-right: 20px;
}

@media screen and (max-width: 919px) {
  .modal-image-1 {
    display: none;
  }

  .modal-button-container {
    display: flex;
    flex-direction: column;
  }

  .modal-button-spacing {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .mobile-modal-button-width {
    width: 100% !important;
  }
}
