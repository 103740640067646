/** @format */

.contactUs-inner-container {
  margin-bottom: 100px;
}

.contactUs-field-container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-dark-700);
}

.contactUs-description {
  margin-bottom: 40px;
}

.contactUs-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
}

.contactUs-field-textarea {
  min-height: 150px;
  border-radius: 24px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
  resize: none;
}

.contactUs-field-textarea:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.contactUs-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.contactUs-first-row {
  margin-bottom: 40px;
}

.contactUs-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.contactUs-button-spacing {
  margin-right: 20px;
}

.contact-us-fria-maklare-info {
  border: 1px solid var(--color-neutral-dark-600);
  border-radius: 8px;
  height: 260px;
  margin-top: 70px;
  text-align: center;
  padding: 84px 0;
  margin-bottom: 80px;
}

.contact-us-fria-maklare-info .info-box p {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 400;
}

@media screen and (max-width: 919px) {
  .contactUs-image-1 {
    display: none;
  }

  .contactUs-button-container {
    display: flex;
    flex-direction: column;
  }

  .contactUs-button-spacing {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .mobile-contactUs-button-width {
    width: 100% !important;
  }

  .contact-us-fria-maklare-info {
    height: 165px;
    padding: 30px 0;
    margin-bottom: 36px;
  }
}
