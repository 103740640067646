/** @format */

.aboutuspage-main-title {
  text-align: center;
  margin-bottom: 50px;
}

.aboutuspage-section {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}

.aboutuspage-section-1 {
  margin-bottom: 150px;
}

.mobile-aboutuspage-section-1 {
  margin-bottom: 50px;
}

.aboutuspage-section-2 {
  margin-bottom: 250px;
}

.aboutuspage-innersections {
  display: flex;
  flex: 1;
}

.aboutuspage-innersections-img {
  width: 100%;
}

.aboutuspage-text-right-spacing {
  margin-right: 75px;
}

.aboutuspage-text-left-spacing {
  margin-left: 75px;
}

.aboutuspage-text-bottom-spacing {
  margin-bottom: 50px;
}

.aboutuspage-text-top-spacing {
  margin-top: 50px;
}

.mobile-aboutuspage-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 919px) {
  .mobile-aboutuspage-image-spacing {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 919px) {
  .mobile-aboutuspage-section {
    display: none;
  }
}
