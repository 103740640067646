/** @format */

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 5%;
  max-width: 1440px;
  width: auto;
  height: var(--height-header);
  border-radius: 0px;
  margin: auto;
}

.header-left-container {
  flex: 1;
  height: inherit;
  display: flex;
}

.header-left-container .logo {
  flex: 1;
}

.header-middle-container {
  flex: 1;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-right-container {
  flex: 1;
  height: inherit;
  display: flex;
  justify-items: right;
  padding-right: 5%;
}

.header-button-item {
  display: flex;
  align-self: center;
  height: 47px;
}

.header-button-item:first-child {
  flex: 1;
  justify-content: right;
  margin-right: 15px;
}

.header-button-item:last-child {
  flex: 1;
  justify-self: flex-start;
}

.header-right-container .btn {
  min-width: 150px;
}

/** mobile header styles **/

.mobile-header-right-right-block {
  position: absolute;
  top: 0;
  right: 0;
}

.mobile-header-right-right-block .menu-wrapper {
  width: 100vw;
  height: 261px;
}

.mobile-header-right-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: auto;
  margin-top: 99px;
}

.dropdown-toggle::after {
  display: none !important;
}

.find-broker-search-bar {
  border-radius: 40px;
  padding: 10px 20px;
  border: 2px solid var(--color-primary-pink);
  width: 80%;
  position: relative;
}

.find-broker-search-bar .find-broker-search-icon {
  color: var(--color-primary-pink);
  margin-right: 5px;
}

.find-broker-search-bar input {
  width: 70%;
  border: 0px;
}

.find-broker-search-bar input:focus {
  outline: none;
}

.header-middle-container .broker-contact-header-button {
  width: 80% !important;
}

.header-middle-container .broker-contact-header-button:focus-within {
  color: var(--color-primary-pink) !important;
  opacity: 1;
}

.hamburger-menu-floating-button {
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border: 2px solid var(--color-neutral-dark-600);
  background-color: #fff;
  display: none;
}

.hamburger-menu-floating-button-open {
  background-image: var(--icon-hamburger-dark);
  background-position: center;
  background-repeat: no-repeat;
}

.hamburger-menu-floating-button-close {
  background-image: var(--icon-close);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40% 40%;
}

.mobile-header-overlay {
  position: absolute;
  background-color: white;
  z-index: 10;
  width: 100%;
  top: 0;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 230px;
  padding-left: 10px;
  padding-right: 10px;
}

.broker-profile-view-mobile .mobile-header-overlay {
  width: 95%;
}

@media screen and (max-width: 919px) {
  .header-button-item {
    display: none;
  }
  .hamburger-menu-floating-button {
    display: block;
  }

  .header-right-container {
    display: none;
  }

  .header-middle-container .find-broker-search-bar {
    width: 100% !important;
  }

  .header-middle-container .broker-contact-header-button {
    width: 85% !important;
    max-width: 185px;
    font-size: 11px;
    padding: 0px;
  }

  .header-middle-container .broker-contact-header-button-wrapper {
    display: flex;
    justify-content: center;
  }

  .header-left-container {
    max-width: 100px;
    margin-right: 15px;
  }
}
