/**
 * /* what we do *
 *
 * @format
 */

.what-we-do-container {
  margin-top: 250px;
  margin-bottom: 156px;
}

.what-we-do-container .post-block-container {
  flex-direction: row-reverse;
}

.what-we-do-container .post-block-container .post-block-left {
  flex: 5;
  padding-right: 140px;
}

.what-we-do-container .post-block-container .post-block-right {
  flex: 2;
  margin-right: 2.5rem;
}

@media (max-width: 919px) {
  .what-we-do-container {
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 15px;
  }

  .what-we-do-container .post-block-container .post-block-right {
    display: none;
  }

  .what-we-do-container .post-block-container .post-block-left {
    padding-right: unset;
  }
}
