/** @format */

.leads-generator-accordion {
  margin-left: 15px;
  width: 100%;
  max-width: 1200px;
}

.leads-generator-accordion-header .accordion-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-primary-pink);
}

.leads-generator-accordion-header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-neutral-neutral-100);
}

.leads-generator-accordion-header
  .accordion-button:not(.collapsed)
  .leads-generator-accordion-header-title {
  color: var(--color-primary-pink);
}

.leads-generator-accordion-header .accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:svgjs='http://svgjs.com/svgjs' xmlns:xlink='http://www.w3.org/1999/xlink' width='288' height='288'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='288' fill='%230c63e4' viewBox='0 0 16 16'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' class='color000 svgShape'/%3E%3C/svg%3E%3C/svg%3E");
}

.leads-generator-accordion-wrapper {
  display: flex;
  justify-content: center;
}

.leads-generator-body {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 1200px;
}

.leads-generator-body .body-title-section {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.leads-generator-body .leads-selector-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
}

.leads-generator-body .leads-selector-button-group button {
  width: 100%;
}

.leads-generator-body .leads-selector-button-group .btn-pink:focus-within {
  opacity: 1;
}

.leads-generator-body .lead-selector-button-wrapper {
  width: 100%;
  padding-right: 20px;
}

.leads-generator-body .leads-additional-info-textarea {
  min-height: 150px;
  border-radius: 24px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
  resize: none;
}

.leads-generator-page-wrapper {
  margin-left: 20px;
  display: flex;
  justify-content: center;
}

.leads-generator-page-wrapper .leads-generator-body {
  width: 100%;
}

.leads-generator-body .leads-submit-button {
  width: 50%;
}

.leads-generator-body .alert {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.leads-generator-modal .modal-inner-container {
  margin-bottom: 30px;
}

@media screen and (max-width: 919px) {
  .leads-generator-accordion {
    margin-left: 5px;
  }

  .leads-generator-page-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .leads-generator-body {
    margin-left: 0px;
    margin-right: 0px;
  }

  .leads-generator-body .lead-selector-button-wrapper {
    width: 30%;
    padding-right: 0px;
  }

  .leads-generator-body .lead-selector-button-wrapper .btn {
    min-width: 0%;
    width: 100%;
  }
}
