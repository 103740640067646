/**
 * benefits cards
 *
 * @format
 */

.homepage-benefits-container {
  display: flex;
  margin: 281px auto;
  flex-direction: row;
  margin-left: 0;
  width: calc(100% + 1.5rem);
}

.homepage-benefits-container .icon-benefits {
  margin: -3px auto;
  max-height: 90px;
}

.homepage-benefits-container .benefits-item {
  flex: 1;
  color: var(--color-neutral-neutral-100);
  min-width: unset !important;
  max-width: 25%;
  width: auto;
  padding: 0 1.8rem;
  height: 480px;
  border-radius: unset;
  border: unset;
}

.homepage-benefits-container .benefits-item .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.homepage-benefits-container .benefits-item .card-text {
  color: var(--color-neutral-neutral-100);
}

.homepage-benefits-container h4 {
  color: var(--color-neutral-neutral-100);
}

.benefits-item-1 {
  background-color: var(--color-primary-pink-dark);
}

.benefits-item-2 {
  background-color: var(--color-primary-pink-darker);
}

.benefits-item-3 {
  background-color: var(--color-neutral-dark-650);
}

.benefits-item-4 {
  background-color: var(--color-primary-yellow);
}

@media (max-width: 919px) {
  .homepage-benefits-container {
    margin-left: -0.75rem !important;
    margin-bottom: 0;
    margin-top: 150px;
  }

  .homepage-benefits-container .card-group {
    flex-direction: column !important;
  }

  .homepage-benefits-container .card-title {
    width: 100%;
  }

  .homepage-benefits-container .benefits-item {
    min-width: 320px !important;
    max-width: 100vw;
    width: auto;
    padding: 0 1.8rem;

    margin-bottom: 0;
  }

  .homepage-benefits-container .icon-benefits {
    display: none;
  }

  .homepage-benefits-container .benefits-item .card-body {
    overflow: auto;
    padding: 1rem 2.2rem;
    justify-content: space-evenly;
  }
}
