/** @format */

.faqpage-title {
  text-align: center;
  margin-bottom: 100px;
}

.faqpage-main-section {
  display: flex;
  flex-direction: row;
}

.faqpage-search-field {
  margin-bottom: 30px;
}

.faqpage-search-field input {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: none !important;
  border-radius: 10px;
  width: 100%;
}

.faqpage-search-field .input-group .input-group-text {
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.faqpage-column-1 {
  flex: 1;
  padding: 20px;
}

.faqpage-column-2 {
  flex: 3;
  padding: 20px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button {
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  color: var(--color-neutral-dark-700);
}

.accordion-button:not(.collapsed) {
  color: var(--color-primary-pink);
  background-color: white;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:svgjs='http://svgjs.com/svgjs' xmlns:xlink='http://www.w3.org/1999/xlink' width='288' height='288'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='288' fill='%230c63e4' viewBox='0 0 16 16'%3E%3Cpath fill='%23cf6584' fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' class='color000 svgShape'/%3E%3C/svg%3E%3C/svg%3E");
}

.accordion-item {
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 10px !important;
  overflow: hidden;
}

.accordion-body {
  padding: 0.5rem 1.25rem;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-neutral-dark-700);
}

.faqpage-category-list {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.faqpage-category-list .list-group {
  padding: 10px;
}

.form-control:focus {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.list-group {
  gap: 10px;
}

.list-group-item {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-neutral-dark-700);
  cursor: pointer;
}

.pink-text {
  color: var(--color-primary-pink);
}

.faqpage-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0px;
}

.faqpage-contact .btn {
  width: fit-content !important;
}

.faqpage-center {
  text-align: center;
}

.faqpage-spacing {
  margin-bottom: 50px;
}
