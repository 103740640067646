/** @format */

.fmhubpage-main-title {
  text-align: center;
  margin-bottom: 150px;
}

.fmhubpage .image-article-page-1 {
  width: 100%;
  margin-bottom: 50px;
}

.fmhubpage-section {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}

.fmhubpage-section-buttons {
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fmhubpage-text-right-spacing {
  margin-right: 75px;
}

.fmhubpage-text-left-spacing {
  margin-left: 75px;
}

.fmhubpage-text-bottom-spacing {
  margin-bottom: 50px;
}

.fmhubpage-button-spacing-right {
  margin-right: 15px;
}

.mobile-fmhubpage-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobile-fmhubpage-fullwidth-buttons {
  width: 100% !important;
}

.fmhubpage-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 919px) {
  .fmhubpage-section {
    display: none;
  }
  .fmhubpage-desc {
    display: none;
  }

  .mobile-fmhubpage-image-spacing {
    margin-bottom: 75px;
  }
  .mobile-fmhubpage-section img{
    height: intrinsic;
  }
}

@media screen and (min-width: 919px) {
  .mobile-fmhubpage-section {
    display: none;
  }
  .mobile-fmhubpage-section img {
    height:auto
  }
  .mobile-fmhubpage-desc {
    display: none;
  }
}
