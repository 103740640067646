/**
 * @format
 */

.post-block-container {
  display: flex;
  padding: 6px;
}

.post-block-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.post-block-right {
  flex: 1;
}

.post-block-left .heading-container {
  flex: 1;
}

.post-block-left .text-container {
  flex: 1;
}

@media (max-width: 919px) {
  .post-block-container .heading-container,
  .post-block-container .text-container {
    text-align: center;
  }

  .post-block-container .post-block-left {
    margin-bottom: 40px;
  }
}
