/** @format */

.homepage-partner-container {
  margin-top: 75px;
}

.homepage-partner-container .partner-content {
  display: flex;
  text-align: center;
}

.homepage-partner-container .partner-content h3 {
  flex: 1;
  align-self: center;
  margin-bottom: 275px;
}

@media (min-width: 920px) {
  .homepage-partner-container .image-partners-monochrome-mobile {
    display: none;
  }

  .homepage-partner-container .image-partners-monochrome {
    display: flex;
  }
}

@media (max-width: 919px) {
  .homepage-partner-container {
    margin: -70px auto 0;
  }

  .homepage-partner-container .image-partners-monochrome-mobile {
    display: flex;
  }

  .homepage-partner-container .image-partners-monochrome {
    display: none;
  }
}
