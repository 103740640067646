/** @format */

.onboarding-main-container-spacing {
  padding-top: 20px;
}

.onboardingwelcome-header-text {
  color: white;
  text-align: center;
  padding-top: 40px;
}

.onboardingsteps-main-container {
  display: flex;
  height: 100vh;
  padding: 20px;
  flex-direction: row;
}

.onboardingsteps-mobile-main-container {
  display: flex;
  height: 100vh;
  padding: 20px;
  flex-direction: column;
}

.onboardingsteps-side-nav {
  flex: 1 1;
  background-color: var(--color-primary-pink);
  height: 100%;
  border-radius: 10px;
  overflow: auto;
}

.onboardingsteps-main-body {
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 40px;
}

.onboardingsteps-note-heading {
  color: var(--color-primary-pink) !important;
}

.onboardingsteps-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px 30px;
}

.onboardingsteps-heading {
  margin-bottom: 40px;
}

.onboardingsteps-content-body {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

.onboardingstep-button-container {
  display: flex;
  flex-direction: row;
}

.onboardingstep-button-container .btn.btn-white {
  background-color: inherit;
  border-color: inherit;
}

.onboardingstep-button-container-top-spacing {
  margin-top: 40px;
}

.onboardingsteps-column-container {
  display: flex;
  flex-direction: column;
}

.onboardingsteps-usp-column-container {
  display: block ruby;
}

.onboarding-upload-container {
  margin-bottom: 30px;
}

.onboardingsignup-field-input {
  margin-bottom: 20px;
}

.onboarding-dropzone {
  text-align: center;
  padding: 30px;
  border: 2px dashed var(--color-neutral-dark-500);
  background-color: white;
  cursor: pointer;
  width: 40%;
}

.onboarding-dropzone-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  width: fit-content !important;
}

.onboarding-dropzone-remove-mb {
  margin-bottom: 0px;
  padding: 10px 40px;
}

.onboarding-dropzone-body {
  display: flex;
  flex-direction: column;
}

.onboarding-dropzone-icon {
  margin-bottom: 20px;
}

.onboarding-dropzone-thumbscontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.onboarding-dropzone-thumb {
  display: inline-flex;
  width: 150px;
  height: 150px;
  padding: 4px;
  box-sizing: border-box;
}

.onboarding-dropzone-thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  width: 100%;
}

.onboarding-dropzone-thumb-img {
  display: block;
  width: auto;
  height: 100%;
}

.onboarding-field-textarea {
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  border: 1px solid var(--color-neutral-dark-600);
  min-height: 150px;
}

.onboarding-field-textarea:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.onboarding-row {
  display: flex;
  flex-direction: row;
}

.pink-text {
  color: var(--color-primary-pink) !important;
}

.text-left-align {
  text-align: left;
}

.stepper-mobile-main-container {
  border-radius: 10px;
  height: 100%;
  background-color: var(--color-primary-pink);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper-mobile-header-image {
  padding: 20px;
}

.stepper-mobile-overlay {
  position: absolute;
  width: 100%;
}

.stepper-mobile-overlay-closed {
  display: none;
}

.stepper-mobile-icon-spacing {
  margin-right: 20px;
  color: white;
  cursor: pointer;
}

.onboardingsteps-main-hide-overflow {
  overflow: hidden;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.790363 1.75L9.4987 10.4583L18.207 1.75' stroke='%237A7A7A' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat;
  background-position: calc(100% - 12px) center !important;
  padding: 8px 32px 8px 16px;
}

@media screen and (max-width: 919px) {
  .onboarding-dropzone {
    width: 100%;
  }

  .onboardingsteps-main-body {
    padding: 0px;
    margin-top: 20px;
  }
}
