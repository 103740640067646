/** @format */

@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  /** colors **/

  /* pink */
  --color-primary-pink: #cf6584;
  --color-primary-pink-light: #cf65841a;
  --color-primary-pink-dark: #d16685;
  --color-primary-pink-darker: #aa4e69;
  --color-secondary-pink: #e39097;

  /* yellow */
  --color-primary-yellow: #fb9e44;
  --color-secondary-yellow-light: #ffb967;
  --color-secondary-yellow-lighter: #fee3c3;

  /* grey */
  --color-neutral-dark-900: #2c2c2c;
  --color-neutral-dark-800: #2c2c2c;
  --color-neutral-dark-700: #4e4e4e;
  --color-neutral-dark-650: #5c5c5c;
  --color-neutral-dark-600: #7a7a7a;
  --color-neutral-dark-500: #b3b3b3;
  --color-neutral-neutral-300: #e1e1e1;
  --color-neutral-neutral-200: #f1f1f1;
  --color-neutral-neutral-100: #ffffff;

  /* red */
  --color-primary-red: #d95353;

  /* non-primary-colors */

  --color-secondary-green: #47b87b;

  /** breakpoints **/

  --breakpoint-small-start: 400px;
  --breakpoint-small-end: 767px;
  --breakpoint-medium-start: 768px;
  --breakpoint-medium-end: 1149px;
  --breakpoint-large-start: 1150px;

  /** fonts **/

  --font-inter: 'Inter', sans-serif;
  --font-cinzel: 'Cinzel', serif;

  /** logos **/

  --logo-fria-maklare-dark: url('../../images/logos/logo-fria-maklare-dark.svg');
  --logo-fria-maklare-light: url('../../images/logos/logo-fria-maklare-light.svg');
  --logo-fria-maklare-light-no-text: url('../../images/logos/logo-fria-maklare-light-no-text.svg');

  /** icons **/

  --icon-close: url('../../images/icons/icon-close.svg');
  --icon-add-new: url('../../images/icons/icon-add-new.svg');
  --icon-location: url('../../images/icons/icon-location.svg');
  --icon-bathrooms: url('../../images/icons/icon-bathrooms.svg');
  --icon-dimensions: url('../../images/icons/icon-dimensions.svg');
  --icon-bedrooms: url('../../images/icons/icon-bedrooms.svg');
  --icon-benefits-item-1: url('../../images/icons/icon-benefits-item-1.svg');
  --icon-benefits-item-2: url('../../images/icons/icon-benefits-item-2.svg');
  --icon-benefits-item-3: url('../../images/icons/icon-benefits-item-3.svg');
  --icon-benefits-item-4: url('../../images/icons/icon-benefits-item-4.svg');
  --icon-twitter-light: url('../../images/icons/icon-twitter-light.svg');
  --icon-instagram-light: url('../../images/icons/icon-instagram-light.svg');
  --icon-facebook-light: url('../../images/icons/icon-facebook-light.svg');
  --icon-linkedin-light: url('../../images/icons/icon-linkedin-light.svg');
  --icon-hamburger-dark: url('../../images/icons/icon-hamburger-dark.svg');
  --icon-swimming-pools: url('../../images/icons/icon-swimming-pools.svg');
  --icon-what-we-offer-1: url('../../images/icons/icon-what-we-offer-1.svg');
  --icon-what-we-offer-2: url('../../images/icons/icon-what-we-offer-2.svg');
  --icon-what-we-offer-3: url('../../images/icons/icon-what-we-offer-3.svg');
  --icon-what-we-offer-1-mobile: url('../../images/icons/icon-what-we-offer-1-mobile.svg');
  --icon-what-we-offer-2-mobile: url('../../images/icons/icon-what-we-offer-2-mobile.svg');
  --icon-what-we-offer-3-mobile: url('../../images/icons/icon-what-we-offer-3-mobile.svg');
  --icon-go-back: url('../../images/icons/icon-go-back.svg');
  --icon-email-pink: url('../../images/icons/icon-email-pink.svg');
  --icon-phone-pink: url('../../images/icons/icon-call-pink.svg');
  --icon-copy-pink: url('../../images/icons/icon-copy-pink.svg');
  --icon-exclamation: url('../../images/icons/icon-exclamation.png');

  /** images **/

  --image-contact-us: url('../../images/images/image-contact-us.png');
  --image-homepage-1: url('../../images/images/image-homepage-1.png');
  --image-homepage-2: url('../../images/images/image-homepage-2.png');
  --image-homepage-3: url('../../images/images/image-homepage-3.png');
  --image-article-page-1: url('../../images/images/image-article-page-1-1.png');
  --image-article-page-2: url('../../images/images/image-article-page-2.png');
  --image-article-page-3: url('../../images/images/image-article-page-3.png');
  --image-partners-monochrome: url('../../images/images/image-partners-monochrome.png');
  --image-partners-monochrome-mobile: url('../../images/images/image-partners-monochrome-mobile.png');
  --image-registration-page: url('../../images/images/image-registration-page.png');
  --image-contact-us: url('../../images/images/image-contact-us.png');
  --image-sample-profile-object: url('../../images/images/image-sample-profile-object.png');
  --image-sample-broker-profile-picture: url('../../images/images/image-sample-broker-profile-picture.png');
  --image-profile-background: url('../../images/images/image-profile-background.svg');
  --image-profile-overlay: url('../../images/images/image-profile-overlay.svg');
  --image-broker-profile-not-public: url('../../images/images/image-broker-profile-not-public.png');

  /** predefined dimensions **/
  --height-footer: 258px;
  --height-header: 167px;
}

body {
  background-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-600);
}

body,
button,
p,
ul,
li,
ol,
form,
div,
span {
  font-weight: 600;
  font-size: 16px;
  line-height: auto;
  font-family: var(--font-inter);
  color: var(--color-neutral-dark-800);
}

body .text1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
}

body .text2r {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-neutral-dark-600);
}

body .text3m {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-neutral-dark-800);
}

body .text3m-span {
  padding-bottom: 25px;
}

body .text3r {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(--color-neutral-dark-600);
}

body .text4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-neutral-dark-600);
}

body .text5m {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

h1,
h2,
h3,
h4 {
  font-weight: 900;
  font-family: var(--font-cinzel);
  color: var(--color-neutral-dark-900);
}

h1 {
  font-size: 80px;
  line-height: 80px;
}

h2 {
  font-size: 64px;
  line-height: 80px;
}

h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
}

h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 34px;
}

.main-container {
  position: relative;
  min-height: 100vh;
  margin-bottom: -16px;
}

.inner-container {
  min-height: calc(100vh - var(--height-header) - var(--height-footer));
}

.content-wrapper {
  min-height: calc(100vh - var(--height-header) - var(--height-footer));
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  padding-right: unset;
  padding-left: unset;
}

.form-check-input:checked {
  background-color: var(--color-primary-pink);
  border-color: var(--color-primary-pink);
}

form .required-label:after {
  content: '*';
}

.alert-danger {
  background: #f8d7da;
  border: 1px solid #f8d7da;
  color: var(--color-primary-red);
  font-weight: 400;
  font-size: 16px;
}

.alert span {
  font-weight: 400;
  font-size: 16px;
}

.alert-danger span {
  color: var(--color-primary-red);
}

@media (max-width: 919px) {
  body {
    font-weight: 600;
  }

  h1 {
    font-size: 52px;
    line-height: 64px;
  }

  h2 {
    font-size: 40px;
    line-height: 56px;
  }

  h3 {
    font-size: 32px;
    line-height: 40px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
  }

  .content-wrapper {
    margin-right: initial;
    margin-left: initial;
    padding-right: initial;
    padding-left: initial;
  }
}
