/** @format */

.footer {
  background-color: var(--color-primary-pink);
  color: var(--color-neutral-neutral-100);
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-height: 240px;
  height: var(--height-footer);
  width: calc(100% + 1.5rem);
  border-radius: 0px;
  margin: 0 0 -0.75rem -12px;
}

.footer-top {
  flex: 1;
  display: flex;
  color: var(--color-neutral-neutral-100);
  margin: 56px auto;
}
.footer-bottom {
  flex: 1;
  display: flex;
  color: var(--color-neutral-neutral-100);
}

/** footer top prime trio boxes **/

.footer .footer-top-left {
  flex: 1;
  background: transparent;
}

.footer-top-middle {
  flex: 3;
  background: transparent;
  align-self: center;
  color: var(--color-neutral-neutral-100);
}

.footer-top-right {
  flex: 1;
  background: transparent;
  color: var(--color-neutral-neutral-100);
  display: flex;
}

/** end footer top prime trio boxes **/

/** social icons **/

.footer-top-right .social-icon-container {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  height: 25px;
  display: flex;
  align-self: center;
  max-width: 165px;
  margin: auto;
}

.footer-top-right .left {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  background: transparent;
}

.footer-top-right .center {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  background: transparent;
}

.footer-top-right .center-right {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  background: transparent;
}

.footer-top-right .right {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  background: transparent;
}

.footer-top-right .social-icon {
  color: var(--color-neutral-neutral-100);
  flex: 1;
  background: transparent;
}

/** end social icons **/

.footer-bottom-left {
  flex: 1;
  align-self: center;
  color: var(--color-neutral-neutral-100);
}

.footer-bottom-middle {
  flex: 3;
}

.footer-bottom-right {
  flex: 1;
}

.footer-bottom .copyright {
  color: var(--color-neutral-neutral-100);
}

.footer-links-container {
  display: flex;
  justify-content: space-around;
  max-width: 350px;
  margin: auto;
  text-align: justify;
}

.footer-link-item {
  white-space: nowrap;
}

.footer-link-item.css-bullet {
  color: var(--color-neutral-neutral-100);
}

.footer-links-container a {
  text-decoration: none;
  color: var(--color-neutral-neutral-100);
}

.footer .bullet-style {
  font-size: 4px;
  vertical-align: middle;
}

@media screen and (min-width: 920px) {
  .footer-top-middle {
    display: block;
  }

  .footer-links-mobile {
    display: none;
  }
}

@media screen and (max-width: 919px) {
  .footer {
    padding: 5px 15px;
  }

  .footer-top-middle {
    display: none;
  }

  .footer-links-mobile {
    display: flex;
    height: 50%;
    align-items: center;
  }

  .footer-bottom {
    margin-top: 20px;
  }

  .footer-bottom-left {
    flex: 3;
  }

  .logo-fria-maklare-light {
    background-size: 55%;
  }

  .footer-top {
    margin: 20px auto;
  }

  .footer-links-container {
    max-width: 85vw;
  }
}
