/** @format */

.logo-fria-maklare-dark {
  background-image: var(--logo-fria-maklare-dark);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  max-width: 162px;
  height: 55px;
  margin: 56px auto auto 0;
}

.logo-fria-maklare-light {
  background-image: var(--logo-fria-maklare-light);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  max-width: 169px;
  height: 57px;
}
