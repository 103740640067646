/** @format */

.image-homepage {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.image-homepage-1 {
  background-image: var(--image-homepage-1);
  height: 677px;
}

.image-homepage-2 {
  background-image: var(--image-homepage-2);
  max-width: 300px;
  height: 340px;
}

.image-homepage-3 {
  background-image: var(--image-homepage-3);
  margin: auto;
  width: 213px;
  height: 390px;
}

.image-profile-background {
  background-image: var(--image-profile-background);
  background-repeat: no-repeat;
  margin: auto;
  width: 276px;
  height: 395px;
  text-align: center;
  margin-top: 80px;
}

.image-profile-foreground {
  border-radius: 8px;
  max-width: 220px;
  max-height: 381px;
}

.image-profile-overlay {
  background-image: var(--image-profile-overlay);
  background-repeat: no-repeat;
  position: relative;
  width: 120px;
  height: 120px;
  left: 196px;
  top: -108px;
}

.image-partners-monochrome {
  background-image: var(--image-partners-monochrome);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1440px;
  height: 740px;
  margin: auto;
}

.image-partners-monochrome-mobile {
  background-image: var(--image-partners-monochrome-mobile);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  height: auto;
  margin: auto;
}

.image-broker-profile-not-public {
  background-image: var(--image-broker-profile-not-public);
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  height: 1300px;
  width: 100%;
  text-align: center;
}
