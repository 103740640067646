/** @format */

a,
a:hover {
  text-decoration: none !important;
}

.btn {
  min-width: 150px;
  height: 47px;
  width: max-content;
  gap: 10px;
  display: flex;
  padding: 16px 40px;
  flex-direction: row;
  align-items: center;
  border-radius: 40px;
}

.btn-pink {
  border-color: var(--color-primary-pink);
  font-family: var(--font-inter);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;
  background: var(--color-primary-pink);
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-neutral-neutral-100);
}

.btn-pink span {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-neutral-neutral-100);
}

.btn-pink:hover,
.btn-pink:first-child:hover,
:not(.btn-check) + .btn-pink:hover {
  background: linear-gradient(
    93.26deg,
    var(--color-primary-pink) 37.9%,
    var(--color-secondary-yellow-light) 158%
  );
  box-shadow: 2px 3px 8px rgba(44, 44, 44, 0.3);
  border-color: var(--color-pink-dark) !important;
}

.btn-pink:active {
  background: var(--color-primary-pink) !important;
  border: 1px solid var(--color-primary-pink) !important;
}

.btn-pink:focus {
  background: linear-gradient(
    93.26deg,
    var(--color-primary-pink) 37.9%,
    var(--color-secondary-yellow-light) 158%
  );
  filter: drop-shadow(1px 3px 8px rgba(44, 44, 44, 0.3));
}

.btn-pink:focus-within {
  opacity: 0.5;
  background: linear-gradient(
    93.26deg,
    var(--color-primary-pink) 37.9%,
    var(--color-secondary-yellow-light) 158%
  );
  filter: drop-shadow(1px 3px 8px rgba(44, 44, 44, 0.3));
}

.btn-pink:focus-visible {
  background: var(--color-primary-pink);
  border-color: var(--color-pink-light) !important;
  box-shadow: 2px 3px 8px rgba(44, 44, 44, 0.3);
}

.btn-pink:visited {
  background: var(--color-primary-pink);
}

.btn-pink:disabled {
  pointer-events: none;
  background: var(--color-neutral-dark-500);
  border: 1px solid var(--color-neutral-neutral-300);
  box-shadow: unset;
}

/** button-white **/

.btn-white {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;
  background: var(--color-neutral-neutral-100);
  border: 2px solid var(--color-primary-pink);
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-primary-pink) !important;
}

.btn-white span {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-primary-pink) !important;
}

.btn-white:hover {
  color: var(--color-primary-pink) !important;
  background-color: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-secondary-pink) !important;
  box-shadow: 0 0 0 0.1rem var(--color-secondary-pink) !important;
  border: 2px solid;
}

.btn-white:hover span {
  color: var(--color-primary-pink) !important;
}

.btn-white:active {
  background: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-pink-dark) !important;
}

.btn-white:focus {
  color: var(--color-secondary-pink) !important;
  background-color: var(--color-neutral-neutral-100) !important;
}

.btn-white:focus span {
  color: var(--color-secondary-pink) !important;
}

.btn-white:focus-within,
.btn-white:focus-visible {
  color: var(--color-secondary-pink) !important;
  background-color: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-pink-light) !important;
  opacity: 70%;
}

.btn-white:focus-within span,
.btn-white:focus-visible span {
  color: var(--color-secondary-pink) !important;
  opacity: 70%;
}

.btn-white:visited {
  background: var(--color-primary-pink);
}

/* button -ashe */
.btn-ashe {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;
  background: var(--color-neutral-dark-700);
  border: 2px solid var(--color-neutral-dark-700);
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-neutral-neutral-100) !important;
}

.btn-ashe:hover {
  color: var(--color-primary-ashe) !important;
  background-color: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-secondary-ashe) !important;
  box-shadow: 0 0 0 0.1rem var(--color-secondary-ashe) !important;
  border: 2px solid;
}

.btn-ashe:active {
  background: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-ashe-dark) !important;
}

.btn-ashe:focus {
  color: var(--color-secondary-ashe) !important;
  background-color: var(--color-neutral-neutral-100) !important;
}

.btn-ashe:focus-within,
.btn-ashe:focus-visible {
  color: var(--color-secondary-ashe) !important;
  background-color: var(--color-neutral-neutral-100) !important;
  border-color: var(--color-ashe-light) !important;
  opacity: 70%;
}

.btn-plain {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 10px;
  background: var(--color-neutral-neutral-100);
  /* border: 2px solid var(--color-primary-pink); */
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* color: var(--color-primary-pink) !important; */
}

.btn-centered {
  flex: 1;
  margin: auto;
}

@media (max-width: 549px) {
  .btn {
    width: -moz-available;
    width: -webkit-fill-available;
  }
}
