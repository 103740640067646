/** @format */

.registerpage-inner-container {
  margin-bottom: 100px;
}

.registerpage-field-container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-dark-700);
}

.registerpage-description {
  margin-bottom: 40px;
}

.registerpage-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid var(--color-neutral-dark-600);
}

.registerpage-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.registerpage-first-row {
  margin-bottom: 40px;
}

.registerpage-button-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.registerpage-button-spacing {
  margin-right: 20px;
}

.registerpage-validation-error-label {
  color: #fb264c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
}

@media screen and (max-width: 919px) {
  .registerpage-image-1 {
    display: none;
  }
  .registerpage-button-container {
    display: flex;
    flex-direction: column;
  }
  .registerpage-button-spacing {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .mobile-registerpage-button-width {
    width: 100% !important;
  }
}
