/**
 * @format
 */

/* formik field overrides */
.form-control {
  border-radius: 40px;
  padding: 10px 20px;
  border: 1px solid var(--color-neutral-dark-600);
  height: 45px;
}

.form-control:focus {
  border-color: var(--color-pink);
  outline: 0;
  box-shadow: 0 0 0 0.1rem var(--color-pink);
}

.form-label {
  text-transform: uppercase;
  margin: 15px 0 4px;
  font-size: 14px;
  font-family: var(--font-din);
}

.form-error {
  font-size: 12px;
  font-weight: bold;
  color: var(--color-pink-dark);
  margin-left: 10px;
}

.form-group {
  overflow: unset;
}

.radio-input {
  padding-top: 8px;
}

@media screen and (min-width: 920px) {
  .form-group .row {
    height: 94px;
  }
}

@media (max-width: 599px) {
  .form-group .row {
    height: unset;
  }
}
@media (max-width: 919px) {
  .form-control {
    font-size: small;
  }
}
