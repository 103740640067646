/** @format */

.salary-page-view {
  margin-top: 0px;
}

.homepage-intro-container {
  max-height: 593px;
  padding-left: 85px;
}

.homepage-intro-container .post-block-left {
  padding: 40px 0;
  max-width: 530px;
}

.homepage-intro-container .post-block-left h1 {
  padding: 40px 0;
  margin-right: 100px;
}

.homepage-intro-container .post-block-right {
  flex: 2;
}

@media (max-width: 919px) {
  .homepage-intro-container {
    max-height: unset;
    margin-top: -80px;
    padding-left: initial;
    padding-right: initial;
  }

  .homepage-intro-container .post-block-left h1 {
    margin-right: unset;
  }

  .homepage-intro-container .post-block-container {
    flex-direction: column;
  }
  .homepage-intro-container .post-block-container .post-block-left {
    flex-direction: column;
    margin-bottom: unset;
  }

  .homepage-intro-container .button-container {
    align-self: center;
  }
}
