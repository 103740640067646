/** @format */

.articlepage-main-title {
  text-align: center;
  margin-bottom: 150px;
}

.articlepage .image-article-page-1 {
  width: 100%;
  margin-bottom: 50px;
}

.articlepage-section {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}

.articlepage-section-buttons {
  margin-top: 200px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.articlepage-text-right-spacing {
  margin-right: 75px;
}

.articlepage-text-left-spacing {
  margin-left: 75px;
}

.articlepage-text-bottom-spacing {
  margin-bottom: 50px;
}

.articlepage-button-spacing-right {
  margin-right: 15px;
}

.mobile-articlepage-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobile-articlepage-section-buttons {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  margin-bottom: 200px;
}

.mobile-articlepage-fullwidth-buttons {
  width: 100% !important;
}

@media screen and (max-width: 919px) {
  .articlepage-section {
    display: none;
  }
  .articlepage-desc {
    display: none;
  }
  .articlepage-section-buttons {
    display: none;
  }
  .mobile-articlepage-image-spacing {
    margin-bottom: 75px;
  }

  .mobile-articlepage-section img{
    height: intrinsic;
  }
}

@media screen and (min-width: 919px) {
  .mobile-articlepage-section {
    display: none;
  }
  .mobile-articlepage-section img {
    height:auto
  }
  .mobile-articlepage-desc {
    display: none;
  }
  .mobile-articlepage-section-buttons {
    display: none;
  }
}
