/** @format */

.image-background-page-not-found {
  background-image: var(--image-background-page-not-found);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 95.5vh;
}

.horizontal-divider {
  background-color: var(--color-pink);
}

.page-not-found-container {
  text-align: center;
  margin-bottom: 200px;
}

.page-not-found-container h1 {
  text-align: left;
  font-size: 80px;
  font-family: var(--font-cinzel);
  color: var(--color-neutral-dark-900);
  font-style: normal;
  font-weight: 900;
  line-height: 80px;
  display: flex;
  align-items: center;
}
.page-not-found-container h2 {
  font-size: 40px;
}

.page-not-found-container p {
  text-align: left !important;

  max-width: 720px;
}

@media screen (max-width: 919px) {
  .page-not-found-container img {
    margin-bottom: 200px;
    margin-top: 70px;
  }
}
