/** @format */

.onboardingusp-icon-spacing {
  margin-right: 10px;
}

.onboarding-addnew-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.onboarding-remove-icon-spacing {
  margin-left: 20px;
}
