/** @format */

/** sticky what we do */

.sticky-animations-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 30px 20px 30px 85px;
  margin-top: -150px;
}

.sticky-sidebar {
  flex: 1;
  padding: 20px 10px 40px;
  margin-top: 120px;
}

.sticky-content {
  flex: 3;
  padding: 20px;
}

.what-we-offer-container {
  width: 100%;
  height: auto;
  margin-left: 50px;
}

.what-we-offer-container h3 {
  text-transform: uppercase;
  margin-bottom: 80px;
}

.what-we-offer-container .text1 {
  text-transform: uppercase;
}

.what-we-offer-container .post-wrapper {
  display: flex;
}

.what-we-offer-container .post-block-container {
  margin-bottom: 70px;
}

.what-we-offer-container .post-block-container .text-container {
  margin-bottom: 20px;
}

.what-we-offer-container .post-block-left {
  flex: 4;
  padding-right: 25px;
}

.what-we-offer-container .post-block-right {
  display: flex;
  align-self: flex-end;
}

.what-we-offer-container .post-block-right div {
  flex: 1;
}

.what-we-offer-container .button-container {
  display: flex;
  align-self: flex-start;
}

.what-we-offer-container .button-container a {
  flex: 1;
  margin-right: 17px;
}

@media (min-width: 920px) {
  .mobile-no-animations-container {
    display: none;
  }

  .sticky-animations-container {
    display: flex;
  }
}

@media (max-width: 919px) {
  .what-we-offer-container .post-wrapper {
    justify-content: center;
  }

  .what-we-offer-container .post-wrapper .icon-mobile-container {
    margin-right: 104px;
    margin-left: -160px;
  }

  .mobile-no-animations-container {
    display: flex;
  }

  .sticky-animations-container {
    display: none;
  }

  .what-we-offer-container {
    margin-left: unset;
  }

  .what-we-offer-container .post-block-container {
    margin-bottom: 20px;
    padding: 5px 0px 5px 5px;
  }

  .what-we-offer-container .post-block-left {
    padding-right: unset;
  }

  .what-we-offer-container .post-block-right {
    display: none;
  }

  .what-we-offer-container h3 {
    text-align: center;
    margin-bottom: 48px;
  }

  .what-we-offer-container .button-container {
    flex-direction: column;
    align-self: auto;
  }

  .what-we-offer-container .post-block-container .heading-container {
    max-width: 214px;
    margin: auto;
  }

  .what-we-offer-container .post-block-container .text-container {
    padding: 10px;
  }

  .what-we-offer-container .button-container a {
    margin: 8px 10px auto;
  }

  .what-we-offer-container .text1 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
