/** @format */

.c-stepper__item {
  position: relative;
  display: flex;
  gap: 1rem;
  padding-bottom: 2.2rem;
}

.c-stepper__item:before {
  --size: 2.5rem;
  content: '1';
  vertical-align: middle;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: white;
  color: var(--color-primary-pink);
}

.c-stepper__bgInactive:before {
  background-color: var(--color-primary-pink) !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
}

.c-stepper__item:nth-child(2):before {
  content: '2' !important;
}

.c-stepper__item:nth-child(3):before {
  content: '3' !important;
}

.c-stepper__item:nth-child(4):before {
  content: '4' !important;
}

.c-stepper__item:nth-child(5):before {
  content: '5' !important;
}

.c-stepper__item:nth-child(6):before {
  content: '6' !important;
}

.c-stepper__item:nth-child(7):before {
  content: '7' !important;
}

.c-stepper__item:not(:last-child):after {
  content: '';
  position: absolute;
  left: 0;
  top: calc(var(--size));
  transform: translateX(calc(var(--size) / 2));
  bottom: 0;
  width: 2px;
  background-color: #e0e0e0;
}

.c-stepper__lineInactive:not(:last-child):after {
  background-color: transparent;
  background-image: linear-gradient(white 50%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 4px 15px;
  background-repeat: repeat-y;
}

.c-stepper {
  --size: 2.5rem;
  --spacing: 0.5rem;
  padding: 0px;
  margin-bottom: 0px;
}

.c-stepper__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepperbar-header-image {
  margin: 40px 0px;
}

.stepperbar-container {
  padding: 0px 60px;
}

.c-stepper__title {
  color: white !important;
}

.c-stepper__title_opacity {
  opacity: 0.5;
}

.stepperbar-mobile-grid-container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: max-content;
  padding-top: 20px;
}

.stepperbar-mobile-border-radius {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.stepperbar-header-no-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
