/** @format */

@media screen and (max-width: 919px) {
  .calculatorpage-text3m {
    margin-bottom: 0px;
  }

  body .text3m-span {
    padding-bottom: 10px !important;
  }
}
